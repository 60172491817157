import React from 'react';
import Grid from '@material-ui/core/Grid';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import { useTranslation } from 'react-i18next';

const LanguageSwitcher = ({ uiLanguages, changeLanguage, iconPos }) => {
  // const { flag, lang, changeLanguage, languages } = props;
  const { i18n } = useTranslation(['bookingUi', 'bookableLanguages']);
  let currentLand = '';
  let currentLang = [];
  currentLang = uiLanguages.filter((lang) => {
    return lang.includes(i18n.language);
  });
  if (currentLang.length > 0) {
    currentLand = currentLang[0].split('-')[1];
  }
  return (
    <Grid container spacing={1} alignItems="flex-end">
      <div className={iconPos}>
        <InputLabel htmlFor="outlined-flag-native-simple">
          <img src={`https://www.countryflags.io/${currentLand}/flat/24.png`} alt={currentLand} />
        </InputLabel>
      </div>
      <Grid item>
        <Select
          native
          value={`${currentLang}`}
          onChange={(e) => changeLanguage(e.target.value)}
          label="Language"
          inputProps={{
            name: 'Language',
            id: 'outlined-flag-native-simple',
          }}
          style={{ paddingLeft: 45 }}
        >
          {uiLanguages.length > 0
            ? uiLanguages.map((lang) => {
                let text = lang.split('-');
                return (
                  <option key={lang} value={lang}>
                    {text[0]}
                  </option>
                );
              })
            : null}
        </Select>
      </Grid>
    </Grid>
  );
  // }
  // else {
  //   return(<p>no translations loaded</p>)
  // }
};

export default LanguageSwitcher;
