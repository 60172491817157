import React from 'react';
import CircularProgress from '@material-ui/core/CircularProgress';
import Alert from '@material-ui/lab/Alert';
import AlertTitle from '@material-ui/lab/AlertTitle';

import { useTranslation } from 'react-i18next';

import styled from 'styled-components';

const StyledToasterWrapper = styled.div`
  position: fixed;
  right: 0;
  top: 10%;
  max-width: 90%;
  z-index: 5000;
  opacity: 0.97;
`;
const StyledToaster = styled.div`
  position: relative;
  /* left: -50%; */
`;

const Toaster = ({ headline, bodyText, showToast, handleShowToaster, toastState }) => {
  const { t } = useTranslation('bookingErrors');
  return showToast ? (
    <StyledToasterWrapper>
      <StyledToaster onClick={handleShowToaster}>
        <Alert icon={<CircularProgress size="sm" />} severity={toastState}>
          <AlertTitle>{t(headline)}</AlertTitle>
          {t(bodyText)}
        </Alert>
      </StyledToaster>
    </StyledToasterWrapper>
  ) : null;
};

export default Toaster;
