export const apiUrl =
  process.env.NODE_ENV === 'production'
    ? 'https://europe-west3-inquam-services.cloudfunctions.net/api'
    : 'http://localhost:5001/inquam-services/europe-west3/api';

// export const languages = [
//   { key: 'EN', text: 'Englisch' },
//   { key: 'DE', text: 'Deutsch' },
//   { key: 'FR', text: 'Französich' },
//   { key: 'PL', text: 'Polnisch' },
//   { key: 'ES', text: 'Spanisch' },
//   { key: 'RU', text: 'Russisch' },
//   { key: 'others', text: 'Weitere ...' },
// ];

export default { apiUrl };
