import React, { useState, useEffect, Suspense } from 'react';
import Routes from './routes';
import MyNavbar from './components/myNavbar';
import { fetchApi } from './utils';
import { HashRouter as Router } from 'react-router-dom';
// import { CssBaseline } from "@material-ui/core"
import { ThemeProvider } from '@material-ui/core/styles';
import theme from './theme';

import Toaster from './components/Toaster';

import { useTranslation } from 'react-i18next';

// call the /health endpoint to coldstart the firebase functions

async function getLanguages() {
  return fetch('locales/index.json').then((r) => r.json());
}
//Test this
async function getHealth() {
  return fetchApi('/health', 'GET')
    .then((res) => {
      if (res.status !== 200) {
        console.log(res.status);
        return Promise.reject();
      } else {
        return 'healthy';
      }
    })
    .catch((error) => {
      if (!error.response) {
        // network error
        return 'Error: Network Error';
      } else {
        return error.response.data.message;
      }
    });
}

export default function App() {
  const { i18n } = useTranslation(['bookingUi', 'bookingAgb', 'bookingErrors'], { useSuspense: false });
  const changeLanguage = (lng) => {
    i18n.changeLanguage(lng);
  };

  const [healthy, setHealthy] = useState('');
  const [showToaster, setShowToaster] = useState(false);
  const [toastState, setToastState] = useState('');
  const [toastHeadline, setToastHeadline] = useState('bookingErrors:toast_booking_connError_head');
  const [toastBodyText, setToastBodyText] = useState('bookingErrors:toast_booking_connError_text');

  const [uiLanguages, setUiLanguages] = useState([]);

  useEffect(() => {
    const test = async function () {
      let result = await getLanguages();
      setUiLanguages(result);
    };
    if (uiLanguages.length <= 1) {
      test();
    }
  }, [uiLanguages]);

  const handleSetToasterHeadline = (reason) => {
    let allToasterStates = ['error', 'warning', 'info', 'success'];
    console.log(reason, reason.indexOf('Error'), reason.indexOf('Error') > -1);
    if (reason.indexOf('Error') > -1) {
      setToastState(allToasterStates[0]);
    }
    if (reason.indexOf('Agb') > -1) {
      setToastState(allToasterStates[2]);
    }
    if (reason.indexOf('Success') > -1) {
      setToastState(allToasterStates[3]);
    }
    setToastHeadline(reason);
  };
  const handleSetToasterBodyText = (reason) => {
    setToastBodyText(reason);
  };
  const handleShowToaster = () => {
    setShowToaster(!showToaster);
  };

  useEffect(() => {
    const test = async function () {
      let result = await getHealth();
      console.log('healthy is', result);
      if (result === 'healthy') {
        setShowToaster(false);
        setHealthy(result);
      } else if (result === 'Error: Network Error') {
        handleSetToasterHeadline(result);
        setShowToaster(true);
        setHealthy(result);
        test();
      } else {
        handleSetToasterHeadline(JSON.stringify(result));
        setShowToaster(true);
        setHealthy(result);
        test();
      }
    };
    test();
  }, [healthy]);

  return (
    <ThemeProvider theme={theme}>
      <Suspense fallback={<p>Loading</p>}>
        <Router basename="/">
          <Toaster
            headline={toastHeadline}
            bodyText={toastBodyText}
            toastState={toastState}
            showToast={showToaster}
            handleShowToaster={handleShowToaster}
            handleSetToasterBodyText={handleSetToasterBodyText}
            handleSetToasterHeadline={handleSetToasterHeadline}
          />
          <MyNavbar changeLanguage={changeLanguage} uiLanguages={uiLanguages} />
          <Routes
            showToaster={showToaster}
            handleShowToaster={handleShowToaster}
            handleSetToasterBodyText={handleSetToasterBodyText}
            handleSetToasterHeadline={handleSetToasterHeadline}
          />
        </Router>
      </Suspense>
    </ThemeProvider>
  );
}

// <Route path="/topics">
//   <Topics useParams useRouteMatch/>
// </Route>
