import React from 'react';
import { Switch, Route } from 'react-router-dom';

const Landing = React.lazy(() => import('./views/Landing'));
const BookingDialog = React.lazy(() => import('./views/BookingDialog'));
const ConfirmedBooking = React.lazy(() => import('./views/ConfirmedBooking'));
const ThankYou = React.lazy(() => import('./views/ThankYou'));
const MissingPage = React.lazy(() => import('./views/MissingPage'));
const Agb = React.lazy(() => import('./views/Agb'));
const Disclaimer = React.lazy(() => import('./views/Disclaimer'));
const Login = React.lazy(() => import('./views/Login'));

const routes = (props) => {
  const { showToaster, handleShowToaster, handleSetToasterHeadline, handleSetToasterBodyText, matches } = props;

  return (
    <Switch>
      <Route
        exact
        path="/booking"
        render={(props) => (
          <BookingDialog
            matches={matches}
            showToaster={showToaster}
            handleShowToaster={handleShowToaster}
            handleSetToasterHeadline={handleSetToasterHeadline}
            handleSetToasterBodyText={handleSetToasterBodyText}
          />
        )}
      ></Route>
      <Route exact path="/login">
        <Login />
      </Route>
      <Route exact path="/thankyou">
        <ThankYou />
      </Route>
      <Route exact path="/confirmed">
        <ConfirmedBooking />
      </Route>
      <Route exact path="/agb">
        <Agb />
      </Route>
      <Route exact path="/disclaimer">
        <Disclaimer />
      </Route>
      <Route exact path="/">
        <Landing />
      </Route>
      <Route component={MissingPage} />
    </Switch>
  );
};

export default routes;
