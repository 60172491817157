import { apiUrl } from '../config.js';

// function handleErrors(response) {
//     if (!response.ok) {
//       return response.statusText
//     }
//     else {
//       return response.json();
//     }
// }

const fetchApi = (path, methode, data) =>
  fetch(`${apiUrl}${path}`, {
    method: methode,
    body: JSON.stringify(data),
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
  });
// .then(handleErrors)
// .then(response => console.log("ok") )
// .catch(error => console.log(error) );

export default fetchApi;
